<template>
  <div class="relative">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          :id="keyIndex"
          class="pa-3 on-hover position-relative"
          :style="
            isError && !fileName
              ? 'border: 2px dotted red'
              : 'border: 2px dotted #b8b8b8'
          "
          @dragover="dragover"
          @drop="drop"
          v-bind="fileName ? attrs : ''"
          v-on="fileName ? on : ''"
        >
          <input
            type="file"
            name="fields[assetsFieldHandle][]"
            :id="'assetsFieldHandle' + keyIndex"
            style="display: none"
            @change="onChange"
            ref="file"
            accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
          />
          <div class="d-flex justify-space-between">
            <label
              :for="'assetsFieldHandle' + keyIndex"
              class="block cursor-pointer d-flex align-center"
              :style="fileName ? 'max-width: 80%; overflow: hidden' : ''"
            >
              <div
                class="circular-add d-flex align-center justify-center mr-3"
                v-if="!fileName"
              >
                <v-icon color="primary">fas fa-plus</v-icon>
              </div>
              <div v-if="!fileName" style="cursor: pointer">
                Drag & Drop your file or
                <span class="primary--text font-weight-medium">Upload</span>
                <span v-if="Mandatory" style="color: red">*</span>
              </div>
              <div v-else class="d-flex">
                <div class="text-truncate">
                  {{ fileName }}
                </div>
                <!-- <v-icon color="amber" size="30" class="ml-1">create</v-icon> -->
              </div>
            </label>
            <v-icon
              v-if="fileName"
              size="30"
              @click="onFileRemove()"
              color="red"
              class="ml-1"
              >delete</v-icon
            >
          </div>
        </div>
        <div v-if="message && !isError" class="caption">
          {{
            isFileSizeExceed
              ? "Size of uploaded file is greater than 3MB"
              : "Maximum file size: 3 MB ; Supported formats: JPEG, JPG, PNG, PDF, DOC,DOCX"
          }}
        </div>
        <div v-else-if="isError && !fileName" class="caption red--text">
          {{ isError }}
        </div>
      </template>
      <span v-if="fileName">{{ fileName }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "DragAndDropFiles",

  props: {
    fileCategory: {
      type: String,
      required: true,
    },
    oldFileName: {
      type: String,
      required: true,
    },
    keyIndex: {
      type: String,
      default: "file-upload",
    },
    isError: {
      type: String,
      default: "",
    },
    message: {
      type: Boolean,
      default: false,
    },
    Mandatory: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    fileList: {}, // Store our uploaded files
    fileName: "",
    isFileSizeExceed: false,
  }),

  mounted() {
    this.fileName = this.oldFileName;
  },

  watch: {
    oldFileName(val) {
      this.fileName = val;
    },
  },

  methods: {
    onChange() {
      this.fileList = this.$refs.file.files[0];
      if (this.fileList.size > 3000000) {
        this.isFileSizeExceed = true;
      } else {
        this.isFileSizeExceed = false;
        const fr = new FileReader();
        fr.readAsDataURL(this.fileList);
        fr.addEventListener("load", () => {
          let fileContent = fr.result.split(",");
          fileContent = fileContent[1];
          this.fileName = this.fileList.name;
          if (this.oldFileName) {
            this.onFileReplace(fileContent);
          } else {
            this.onFileUpload(fileContent);
          }
        });
      }
    },

    async onFileRemove() {
      this.$refs.file.value = null;
      this.$emit("file-event-triggered", true);
      await this.$store
        .dispatch("removeFile", {
          fileName: this.fileName,
          category: this.fileCategory,
        })
        .then(() => {
          this.$emit("file-event-success", "");
          this.$emit("file-event-triggered", false);
          this.fileList = {};
          this.fileName = "";
        })
        .catch(() => {
          this.$emit("file-event-triggered", false);
        });
    },

    dragover(event) {
      event.preventDefault();
    },

    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },

    async onFileUpload(fileContent) {
      this.$emit("file-event-triggered", true);
      await this.$store
        .dispatch("uploadFile", {
          fileName: this.fileName,
          fileContent: fileContent,
          category: this.fileCategory,
        })
        .then((resFileName) => {
          this.$emit("file-event-success", resFileName);
          this.$emit("file-event-triggered", false);
        })
        .catch(() => {
          this.$emit("file-event-triggered", false);
        });
    },

    async onFileReplace(fileContent) {
      this.$emit("file-event-triggered", true);
      await this.$store
        .dispatch("replaceFile", {
          newFileName: this.fileName,
          fileName: this.oldFileName,
          fileContent: fileContent,
          category: this.fileCategory,
        })
        .then((resFileName) => {
          this.$emit("file-event-success", resFileName);
          this.$emit("file-event-triggered", false);
        })
        .catch(() => {
          this.$emit("file-event-triggered", false);
        });
    },
  },
};
</script>
<style>
.circular-add {
  background: lightgrey;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.on-hover::after {
  position: absolute;
  width: 100%;
  top: 0;
  content: "";
  background: var(--v-primary-base);
  opacity: 0.3;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.on-hover {
  position: relative;
  z-index: 2;
}
</style>
